@tailwind components;
@tailwind utilities;

html, body, #root {
  width: 100%;
  height: 100%;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grade-1 {
  background: linear-gradient(-45deg, #FFF, #53ac3c, #0c6da2, #f9f9f9);
  background-size: 9999% 9999%;
  background-repeat: no-repeat;
  animation: gradient 15s ease-in-out infinite;
}

.grade-2 {
  background: linear-gradient(-45deg, #ff1b1b, #f28be6, #0c6da2, #f9f9f9);
  background-size: 9999% 9999%;
  background-repeat: no-repeat;
  animation: gradient 15s ease-in-out infinite;
}

.grade-3 {
  background: linear-gradient(-45deg, #3fedd6, #f5964d, #0c6da2, #f9f9f9);
  background-size: 9999% 9999%;
  background-repeat: no-repeat;
  animation: gradient 15s ease-in-out infinite;
}

.grade-4 {
  background: linear-gradient(-45deg, #7cf776, #4d01ff, #0c6da2, #f9f9f9);
  background-size: 9999% 9999%;
  background-repeat: no-repeat;
  animation: gradient 15s ease-in-out infinite;
}

.grade-5 {
  background: linear-gradient(-45deg, #FFF, #ffec5a, #0c6da2, #f9f9f9);
  background-size: 9999% 9999%;
  background-repeat: no-repeat;
  animation: gradient 15s ease-in-out infinite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.invertAndOverlay, .invertAndOverlayHoverOnly:hover {
  filter: invert(1);
  mix-blend-mode: overlay;
}

.invertAndDifference:hover, .invertAndDifference:focus {
  filter: invert(1);
  mix-blend-mode: difference;
}

.abe-blinkin {
  animation: blinkination 2.5s ease-in-out infinite;
}

.appear-in {
  animation: appearin 1300ms ease-in-out forwards;
}

.appear-in-delayed {
  opacity: 0;
  animation: appearin-delayed 1300ms ease-in-out forwards;
  animation-delay: 1300ms;
}

.appear-in-delayed-extended {
  opacity: 0;
  animation: appearin-delayed 1300ms ease-in-out forwards;
  animation-delay: 2000ms;
}

.come-up {
  bottom: -2500px;
  animation: getoverhere 1300ms ease-in-out forwards;
  animation-delay: 2000ms;
}

@keyframes appearin {
  0% {
    opacity: 0;
  }
  100% {
    
  }
}

@keyframes appearin-delayed {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes getoverhere {
  0% {
    bottom: -2500px;
  }
  100% {
    bottom: 0;
  }
}


@keyframes blinkination {
  50% {
    opacity: 0.3;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}